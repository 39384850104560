import React from "react"
import { Helmet } from "react-helmet"
import { ColorschemeContext } from "./colorscheme"

export default function Colorpicker({ children }: any) {
  return (
    <ColorschemeContext.Consumer>
      {context => (
        <div>
          <Helmet>
            <meta name="theme-color" content="{context.scheme.color}"></meta>
          </Helmet>
          <button className="button" onClick={() => context.toggle()}>
            Color mode: {context.scheme.name} (click for {context.next.name})
          </button>
        </div>
      )}
    </ColorschemeContext.Consumer>
  )
}
